"use strict";

import * as _ from 'lodash';
import './css/user_custom_rules.css';
import { CookieConsent } from './js/CookieConsent/src/CookieConsent';
import { XrowComponents } from './js/XrowComponents';
import { GoogleTagManager, gtag } from './js/XrowGoogleTagManager/src/XrowGoogleTagManager';
import { XrowStyling } from './js/XrowStyling';
import { XrowTabForm } from './js/XrowTabForm';

if (document.documentElement.hasAttribute('data-editable') === false) {
    var cc = new CookieConsent()
    cc.run()

    var GTM = new GoogleTagManager('GTM-NN5D7RC', 'G-RLDYB21GJW', 'www.googletagmanager.com');

    GTM.addCallToAction("btn-primary", "btn_primary", "Primary Button Click")
    GTM.addCallToAction("btn-secondary", "btn_secondary", "Secondary Button Click")

    // Only customize the lead generation form if the editor is not logged in.
    const tabForm = new XrowTabForm()
    tabForm.trackSubmits(GTM)
    tabForm.trackFocus(GTM)
    tabForm.run()

    XrowStyling.run()
}

XrowComponents.SeoOptimize()

export default XrowComponents
